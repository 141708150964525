<template>
  <div :class="[`field-${field}`]" />
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'service'
export default {
  name: 'ServiceField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field
    },

    isApiValidated: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    value: ''
  }),

  methods: {
    /**
     * @param {object} value
     */
    afterOnValid (value) {
      // Store selected service.
      this.$store.dispatch('service/set', value)
    }
  }
}
</script>
